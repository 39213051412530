import styled from "styled-components";
import webFont from "../../libs/fonts";
const CustomSigninSignupButton = styled.button`

  position: relative;
  background: linear-gradient(83deg, #00ADD1 -4.61%, #C479FC 86.36%);
  color: white;
  height: 52px;
  font-size: var(--font-size-22);
  border-radius: 8px;
  width: 100%;
  line-height: normal;
  text-edge: cap;
  font-weight: 800;
  border: 0;
  overflow: hidden;
  cursor:pointer;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(83deg, #00ADD1 -4.61%, #C479FC 86.36%);
    transition: left 1.5s ease;
  }

  &:hover::before {
    left: 100%;
  }
  
  .button-text {
    position: relative;
    z-index: 1; 
    font-weight: bold;
  }

  @media only screen and (max-width: 600px) {
    font-size: ${webFont.fl};
  }

  button:disabled,
  button[disabled] {
    opacity: 0.5;
  }
`;
export default CustomSigninSignupButton;
