'use client';
import { getItem } from '../../libs/encryptedStorage';
import { Spin } from "antd";
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateUserLogin } from '../../redux/login/reducer';
import EmailLogin from "./emailLogin/page";
import { RootState } from '../../redux/reducers';
import SpinWrapper from '../../components/SpinWrapper/page';
const Login: React.FC = (props) => {
  const { firebase, isLoading } = useSelector((state: RootState) => state.login);
  const dispatch = useDispatch();
  const router = useRouter()
  const [userLoading, setUserLoading] = useState(true);
  const [isValidating, setIsValidating] = useState(false);
  const [emailLoginVisible, setEmailLogin] = useState(false);
  useEffect(() => {
    let userObj = getItem("user");
    if (userObj) {
      if (process.env.NODE_ENV !== "production") {
        dispatch(validateUserLogin({ noChannel: true }));
        setIsValidating(true);
      }
    }
    setUserLoading(false);
  }, []);
  useEffect(() => {
    if (firebase?.user && firebase?.schoolConfig) {
      setIsValidating(false);
      renderContent();
    }
  }, [firebase]);
  useEffect(() => {
    renderContent();
  }, [])
  const renderContent = () => {
    // if (userLoading) return null;
    const pathName = typeof window !== "undefined" ? window.location.pathname : "/";
    const search = typeof window !== "undefined" ? window.location.search : null;
    const redirectToPath = () => {
      if (pathName !== "/") {
        router.push(pathName + search);
      } else {
        router.push("/dashboard");
      }
    };
    if (process.env.NODE_ENV !== "production") {
      if (firebase?.user && firebase?.schoolConfig) {
        redirectToPath();
      } else if (!isValidating) {
        // return <EmailLogin />;
        setEmailLogin(true);
      }
    } else {
      const userObj = getItem("user");
      if (userObj) {
        redirectToPath();
      } else {
        setEmailLogin(true);
      }
    }
  };
  return (
    <div>
      <SpinWrapper spinning={userLoading}>
        {/* {renderContent()} */}
        {emailLoginVisible ? <EmailLogin /> : <p></p>}
      </SpinWrapper>
    </div>
  );
};
export default Login