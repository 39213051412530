let fonts = {
  small: "var(--font-size-12)",
  defaultFont: "var(--font-size-13)",
  medium: "var(--font-size-14)",
  lm: "var(--font-size-15)",
  large: "var(--font-size-16)",
  extraLarge: "var(--font-size-17)",
  fl: "var(--font-size-18)",
  fll: "var(--font-size-20)",
  doubePica: "var(--font-size-24)",
  flll: "30px",
  largest: "40px",
  xLargest: "50px",
  v3_header: "var(--font-size-17)"
};
export default fonts;