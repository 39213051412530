'use client';
import { Spin, } from 'antd';


const SpinWrapper: React.FC<any> = (props) => {
  const { children, height, spinning } = props;
  let defaultHeight = "100vh"
  return (
    <Spin spinning={spinning} style={{ height: height ? height : defaultHeight, maxHeight: height ? undefined : "none" }} >
      {children}
    </Spin>
  )
};
export default SpinWrapper;
