'use client';
import { Form } from "antd";
import { useRouter } from 'next/navigation';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { signIn, setLoading } from "../../../redux/login/reducer";
import formatMsg from "../../../util/formatMsg";
import CustomButtonFilled from "../loginButton";
import CustomLogo from "../logo";
import SignInStyleWrapper from "../signin.style";
import { RootState } from '../../../redux/reducers';
import SpinWrapper from "../../../components/SpinWrapper/page";
import authHelper from "../../../okta/page"
import Helper from "../../../util/helper";

const emailLogin: React.FC<any> = () => {
  const { isLoading, authSuccess, }: any = useSelector((state: RootState) => state.login);
  const dispatch = useDispatch();
  const [email, setEmail] = useState<any>();
  const [password, setPassword] = useState<any>();
  const [hideButtons, setHideButtons] = useState(true)

  const router = useRouter();
  function submit(loginType) {
    try {
      dispatch(setLoading(null))
      let auth0 = authHelper.getauth0Instance(loginType)
      auth0.loginWithRedirect()
    } catch (err) {
    }
  }

  useEffect(() => {
    if (Helper.getUrlParam("code")) {
      setTimeout(() => {
        setHideButtons(false)
      }, 1000);
    } else setHideButtons(false)
    const handleLoad = async () => {
      let auth0 = authHelper.getauth0Instance(null)
      const redirectResult = await auth0.handleRedirectCallback();

      // logged in. you can get the user profile like this:
      const user = await auth0.getUser();
      // if (!user || !user.email_verified) {
      //   notification("error", "User doesn't exist")
      //   return;
      // }
      dispatch(signIn({ email, password, noChannel: true, user }))
    };
    if (getUrlParam("code") && getUrlParam("state")) {
      handleLoad()
    }
  }, []);
  function getUrlParam(param) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  }

  useEffect(() => {
    if (authSuccess) {
      router.push('/login/userSheet');
    }
  }, [authSuccess])

  return (
    <SpinWrapper spinning={isLoading} style={{ height: "60%", maxHeight: "none" }}>
      <SignInStyleWrapper className="isoSignInPage">
        {hideButtons || isLoading ? <></> :
          <div className="isoLoginContentWrapper" >
            <div className="isoLoginContent" >
              <CustomLogo hideBackButton={true} />
              <div className="isoSignInForm" >

                <Form>
                  {/* <div className="isoEmailWrapper" >
                  <Input
                    type="email"
                    onChange={(e) => { setEmail(e.target.value) }}
                    placeholder={formatMsg("placeholder.email")}
                    value={email}
                  />
                </div>
                <div className="isoInputWrapper" >
                  <Input.Password
                    onChange={(e) => { setPassword(e.target.value) }}
                    placeholder={formatMsg("placeholder.password")}
                    value={password}
                  />
                </div>
                <Row justify="end" >
                  <Col>
                    <Link href="/forgotPassword" className="isoForgotPass" >
                      {formatMsg("page.forgetPassSubTitle")}
                    </Link>
                  </Col>
                </Row> */}


                  < div id="recaptcha-container" />
                  <div className="isoCenterComponent isoHelperWrapper" >


                    <CustomButtonFilled
                      onClick={() => submit(null)}
                    >
                      <div className="button-text" >
                        {/* <IntlMessages id="page.signIn" /> */}
                        {formatMsg("signinWithEmail")}
                      </div>
                    </CustomButtonFilled>
                    <CustomButtonFilled
                      onClick={() => submit("sms")}
                      style={{ marginTop: 20 }}
                    >
                      <div className="button-text" >
                        {/* <IntlMessages id="page.signIn" /> */}
                        {formatMsg("signinWithPhone")}

                      </div>
                    </CustomButtonFilled>
                    < br />

                    {/* <Divider style={
                    {
                      color: "#8D8D8D"
                    }
                  } type="horizontal" orientation="center" >
                    OR
                  </Divider> */}

                    {/* <Row justify="space-between" >
                    <Col lg={11} md={24} sm={24} xs={24} className="signinButtonsHolder" >
                      <PrefixIconButton
                        border={true}
                        onClick={() => handleSignInWithGoogle()}
                        borderColor={colors.color_lightGrey}
                        imgSrc={"/images/googleSignIn.svg"}
                        // title={formatMsg("signIn.google")}
                        title={"Sign in with Google"}
                        width={205}
                        height={45}
                        fontWeight="bold"
                        backgroundColor="transparent"
                        marginRight="0px"
                        marginBottom={5}
                      />
                    </Col>
                    < Col lg={11} md={24} sm={24} xs={24} className="signinButtonsHolder" >
                      <Link href="/login/phoneLogin" >
                        <PrefixIconButton
                          border={true}
                          borderColor={colors.color_lightGrey}
                          onClick={() => null}
                          // title={formatMsg("signin.withMobile")}
                          title={"Sign in with Mobile"}
                          width={205} height={45}
                          fontWeight="bold"
                          backgroundColor="transparent"
                          marginRight="0px"
                          marginBottom={5}
                        />
                      </Link>
                    </Col>
                  </Row> */}

                    {/* </Row> */}

                  </div>
                </Form>
              </div>
            </div>
          </div>
        }
      </SignInStyleWrapper>
    </SpinWrapper>
  )
};
export default emailLogin;